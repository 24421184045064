<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <form @submit.prevent="onSubmit">
        <section class="section">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="">
              <h4 class="title">{{ labelData }}</h4>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/data-client">Client </router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link
                      :to="{
                        name: 'DataClientDetail',
                        params: { id: id_client },
                      }"
                      >Detail Client</router-link
                    >
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {{ labelData }}
                  </li>
                </ol>
              </nav>
            </div>
            <div
              class="button-edit"
              v-show="validated == 1 && labelBreadcrumb == 'Edit'"
            >
              <button class="btn-add" type="button" @click="editable">
                <img src="/img/icon-edit1.svg" alt="" style="width: 20px" />
                Edit
              </button>
            </div>
            <div
              v-show="
                (validated != 1 && labelBreadcrumb == 'Edit') ||
                labelBreadcrumb == 'Tambah'
              "
            >
              <button class="btn btn-save" type="submit" :disabled="isSubmit">
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Simpan
              </button>
            </div>
          </div>

          <div class="card info-detail">
            <ul class="nav nav-pills">
              <!-- <li class="nav-item">
                                    <router-link to="/data-client/detail" class="nav-link ">Detail Client
                                    </router-link>
                                </li> -->
              <li class="nav-item">
                <a href="javascript:void(0)" class="nav-link active"
                  >Contact Client
                </a>
              </li>
            </ul>

            <div class="row mt-5">
              <div class="col-12">
                <div class="title">Detail Contact Client</div>
                <div class="sub-title">
                  {{ labelBreadcrumb }} informasi contact client yang diperlukan
                </div>
              </div>
            </div>
            <hr />
            <div class="row mt-2">
              <div class="col-6">
                <div class="form-group">
                  <label for="nama">Nama : </label>
                  <input
                    :disabled="validated == 1 && labelBreadcrumb == 'Edit'"
                    type="text"
                    v-model="formData.nama"
                    @keyup="formChange('nama')"
                    :class="{ 'is-invalid': formError && formError.nama }"
                    class="form-control"
                    id="nama"
                    placeholder="Masukkan Nama Client"
                  />
                  <div class="form-error" v-if="formError && formError.nama">
                    {{ formError.nama }}
                  </div>
                </div>

                <div class="form-group">
                  <label for="no_hp">Nomor Handphone :</label>
                  <input
                    type="text"
                    class="form-control"
                    :disabled="validated == 1 && labelBreadcrumb == 'Edit'"
                    v-maska="'#############################'"
                    v-model="formData.no_handphone"
                    @keyup="formChange('no_handphone')"
                    :class="{
                      'is-invalid': formError && formError.no_handphone,
                    }"
                    id="no_hp"
                    placeholder="Masukkan Nomor Handphone"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.no_handphone"
                  >
                    {{ formError.no_handphone }}
                  </div>
                </div>

                <div class="form-group">
                  <label for="no_telp">Nomor Telepon :</label>
                  <input
                    :disabled="validated == 1 && labelBreadcrumb == 'Edit'"
                    type="text"
                    class="form-control"
                    v-maska="'### #########################'"
                    v-model="formData.no_telp"
                    @keyup="formChange('no_telp')"
                    :class="{ 'is-invalid': formError && formError.no_telp }"
                    id="no_telp"
                    placeholder="Masukkan Nomor Telepon"
                  />
                  <div class="form-error" v-if="formError && formError.no_telp">
                    {{ formError.no_telp }}
                  </div>
                </div>

                <div class="form-group">
                  <label for="no_wa">Nomor Whatsapp :</label>
                  <input
                    :disabled="validated == 1 && labelBreadcrumb == 'Edit'"
                    type="text"
                    class="form-control"
                    v-maska="'#############################'"
                    v-model="formData.no_whatsapp"
                    @keyup="formChange('no_whatsapp')"
                    :class="{
                      'is-invalid': formError && formError.no_whatsapp,
                    }"
                    id="no_wa"
                    placeholder="Masukkan Nomor Whatsapp"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.no_whatsapp"
                  >
                    {{ formError.no_whatsapp }}
                  </div>
                </div>
              </div>

              <div class="col-6">
                <div class="form-group">
                  <label for="email">Email :</label>
                  <input
                    :disabled="validated == 1 && labelBreadcrumb == 'Edit'"
                    type="email"
                    v-model="formData.email"
                    @keyup="formChange('email')"
                    :class="{ 'is-invalid': formError && formError.email }"
                    class="form-control"
                    id="email"
                    placeholder="Masukkan Email"
                  />
                  <div class="form-error" v-if="formError && formError.email">
                    {{ formError.email }}
                  </div>
                </div>

                <div class="form-group">
                  <label for="inputAddress2">Jabatan :</label>
                  <input
                    :disabled="validated == 1 && labelBreadcrumb == 'Edit'"
                    type="text"
                    v-model="formData.jabatan"
                    @keyup="formChange('jabatan')"
                    :class="{ 'is-invalid': formError && formError.jabatan }"
                    class="form-control"
                    id="inputAddress2"
                    placeholder="Masukkan Jabatan"
                  />
                  <div class="form-error" v-if="formError && formError.jabatan">
                    {{ formError.jabatan }}
                  </div>
                </div>

                <div class="form-group">
                  <label for="inputAddress2">Status :</label>
                  <div class="form-group edit-vendor">
                    <Select2
                      :disabled="validated == 1 && labelBreadcrumb == 'Edit'"
                      v-model="formData.status"
                      :class="{ 'is-invalid': formError && formError.status }"
                      :options="statusCompany"
                      placeholder="Status"
                      :settings="{ minimumResultsForSearch: -1 }"
                      @change="formChange('status')"
                      @select="formChange('status')"
                    />
                    <div
                      class="form-error"
                      v-if="formError && formError.status"
                    >
                      {{ formError.status }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../components/Sidebar.vue";
import NavbarDashboard from "../../../components/Navbar.vue";
import Select2 from "vue3-select2-component";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import { checkRules, showAlert } from "../../../helper";
import { maska } from "maska";
import {
  get_DetailContactClient,
  post_SaveContactClient,
} from "../../../actions/client";

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    // TableLite
  },
  directives: {
    maska,
  },
  data() {
    return {
      validated: 1,
      myValue: "aaaa",
      statusCompany: [
        { id: "aktif", text: "Aktif" },
        { id: "tidak", text: "Nonaktif" },
      ], // or [{id: key, text: value}, {id: key, text: value}]
      placeholder: [
        {
          text: "kecamatan",
        },
      ],
      id_client: this.$route.params.id_client,
      id_contact: this.$route.params.id_contact,
      labelData: !this.$route.params.id_contact
        ? "Tambah Contact Client"
        : "Edit Contact Client",
      labelBreadcrumb: !this.$route.params.id_contact ? "Tambah" : "Edit",
      formData: {
        id: "",
        id_client: this.$route.params.id_client,
        nama: "",
        jabatan: "",
        status: "aktif",
        no_telp: "",
        no_handphone: "",
        no_whatsapp: "",
        email: "",
      },
      isSubmit: false,
      formError: [],
      rules: {
        nama: {
          required: true,
        },
        no_handphone: {
          required: true,
        },
        email: {
          required: true,
        },
        status: {
          required: true,
        },
      },
    };
  },

  created() {
    if (this.id_contact) {
      this.getContact();
    }
  },
  methods: {
    editable() {
      this.validated = 2;
    },
    async formChange(key) {
      if (this.rules[key]) {
        this.rules[key].changed = true;
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
    },
    async getContact() {
      await get_DetailContactClient(this.id_contact, (res) => {
        this.formData = { ...this.formData, ...res.data };
        delete this.formData.created_at;
        delete this.formData.updated_at;
      });
    },
    postData() {
      this.isSubmit = true;
      post_SaveContactClient(
        this.formData,
        (res) => {
          this.isSubmit = false;
          var msg =
            res.response_code === 201
              ? "Data contact berhasil ditambahan"
              : "Data contact berhasil diperbarui";
          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: msg,
            onSubmit: () => {
              localStorage.setItem("setContact", true);
              this.$router.push({
                name: "DataClientDetail",
                params: { id: this.id_client },
              });
            },
          });
        },
        () => {
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Terjadi kesalahan, silakan ulangi kembali",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },
    async onSubmit() {
      for (const key in this.rules) {
        if (this.rules[key]) {
          this.rules[key].changed = true;
        }
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (check.success) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: this.formData.id
            ? "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?"
            : "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      }
    },
  },
  watch: {
    id_client: function (newVal) {
      this.id_client = newVal;
      this.formData.id_client = newVal;
      if (!newVal) {
        this.$router.push({ name: "DataClient" });
      }
    },
    id_contact: function (newVal) {
      this.id_contact = newVal;
      this.formData.id = newVal;
      this.labelData = this.id_contact
        ? "Tambah Contact Client"
        : "Edit Contact Client";
      this.labelBreadcrumb = this.id_contact ? "Tambah" : "Edit";
      if (this.id_contact) {
        this.getContact();
      }
    },
  },
};
</script>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>

<style scoped>
.btn-save {
  background-color: #40ddd4;
  color: #fff;
  /* width: 90px; */
  font-size: 16px;
  font-weight: 400;
  padding: 10px 0px;
}

.nav-pills .nav-item .nav-link.active[data-v-161fae0a]:after {
  border-radius: 5px !important;
}

.card {
  border: 1px solid #eaeaea;
  border-radius: 10px;
}

.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}
</style>
